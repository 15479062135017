input[type=submit],
input[type=reset],
input[type=button],
button {
  background-color: $primary;
  border: $primary;
  border-radius: $br;
  color: $white;
  padding: $sm-pad $md-pad;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input[type=submit]::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
}

input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover,
button:hover {
  background-color: darken($primary, 7.5%);
  border-color: darken($primary, 10%);
  color: $white;
}

input[type=submit]:not(:disabled):active,
input[type=reset]:not(:disabled):active,
input[type=button]:not(:disabled):active,
button:not(:disabled):active {
  background-color: darken($primary, 10%);
  border-color: darken($primary, 12.5%);
  color: $white;
}

input[type=submit]:focus,
input[type=reset]:focus,
input[type=button]:focus,
button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem fade_out($primary, 0.5);
}

input[type=submit]:disabled,
input[type=reset]:disabled,
input[type=button]:disabled,
button:disabled {
  opacity: .65;
  cursor: not-allowed;
  background-color: $primary;
  border-color: $primary;
  color: $white;
}
