main,
header,
footer,
article,
section,
aside,
details,
summary {
  margin: 0 auto;
  margin-bottom: $md-pad;
  width: 100%;
}

main {
  display: block;
  margin: 0 auto;
  max-width: $large-breakpoint;
  padding: 0 $md-pad $md-pad;
}

footer {
  border-top: $border;
  padding: $md-pad 0;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

hr {
  border: 0;
  border-top: $border;
  display: block;
  margin-top: $md-pad;
  margin-bottom: $md-pad;
  width: 100%;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: baseline;
}

@media screen and (max-width: $small-breakpoint) {
  article,
  section,
  aside {
    clear: both;
    display: block;
    max-width: 100%;
  }

  img {
    margin-right: $md-pad;
  }
}

embed,
iframe,
video {
  border: 0;
}
